import { Button, ButtonBase } from "@mui/material";
import { styled } from "@mui/system";

const ButtonReality = styled(ButtonBase)({
  backgroundColor: "#4300c4",
  color: "#ffffff",
  width: "fit-content",
  borderRadius: "40px",
  padding: "15px 40px",
  fontSize: "18px",
  magin: "0 auto",
  textTransform: "capitalize",
  cursor: "pointer",
});
export const ButtonReality2 = styled(ButtonBase)({
  backgroundColor: "#f43301",
  color: "#ffffff",
  width: "fit-content",
  borderRadius: "40px",
  padding: "15px 40px",
  fontSize: "18px",
  magin: "0 auto",
  textTransform: "capitalize",
  cursor: "pointer",
});

export const OutlinedButtonReality = styled(Button)({
  width: "fit-content",
  borderRadius: "40px",
  padding: "15px 40px",
  fontSize: "18px",
  magin: "0 auto",
  textTransform: "capitalize",
  cursor: "pointer",
  color: "white",
  borderColor: "#4300c4",
  "&:hover": {
    borderColor: "white",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
});

export const ButtonVote = styled(ButtonBase)({
  backgroundColor: "#0ab504",
  color: "#ffffff",
  width: "fit-content",
  borderRadius: "100px",
  padding: "15px 30px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#0ab504",
    color: "#ffffff",
  },
});

export default ButtonReality;
