import { styled, TextField } from "@mui/material";

export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: "#ffffff",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'transparent',
  },
  '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
    borderRadius: "30px",
    backgroundColor: 'transparent',
    border: 'solid 1px #4300c4',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: "100px",
    color: "#ffffff",
    backgroundColor: 'transparent',
    border: 'solid 1px #4300c4',
    '& fieldset': {
      border: 'solid 1px #4300c4',
    },
    '&:hover fieldset': {
      borderColor: '#4300c4',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4300c4',
    },
  },
});