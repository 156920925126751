import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { createCandidate, createVoter } from "../api/requests";
import {
  ICandidate,
  ICandidateCRM,
  ICandidateRegister,
  IVoterRegister,
} from "../interfaces/inscription";
import ButtonReality from "./button";
import { CssTextField } from "./TextField";
import UploadPhoto from "./UploadPhoto";
import UploadVideo from "./UploadVideo";
import * as Yup from "yup";
import { ThanksParticipate } from "./ThanksParticipate";
import ReactInputMask from "react-text-mask";
import estadosData from "../utils/states_cities.json";
import musicsData from "../utils/musics.json";
import repertoiresData from "../utils/repertoires.json";
import { CssSelect } from "./Select";
import axios from "axios";
import { cpfMask, phoneMask } from "../utils/regexTypes";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleChecked from "@mui/icons-material/CheckCircle";

const Participation = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const [candidate, setCandidate] = useState<ICandidate>();
  const [thanks, setThanks] = useState(false);
  const [loading, setLoading] = useState(false);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("*Obrigatório"),
    email: Yup.string().email("E-mail inválido").required("*Obrigatório"),
    document: Yup.string()
      .required("*Obrigatório")
      .test("is-cpf", "CPF inválido", (value) => {
        if (!value) return false;
        const cpf = value.replace(/[^\d]/g, "");
        if (cpf.length !== 11) return false;
        if (/^(\d)\1+$/.test(cpf)) return false;
        let sum = 0;
        for (let i = 0; i < 9; i++) {
          sum += parseInt(cpf.charAt(i)) * (10 - i);
        }
        let remainder = 11 - (sum % 11);
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.charAt(9))) return false;
        sum = 0;
        for (let i = 0; i < 10; i++) {
          sum += parseInt(cpf.charAt(i)) * (11 - i);
        }
        remainder = 11 - (sum % 11);
        if (remainder === 10 || remainder === 11) remainder = 0;
        if (remainder !== parseInt(cpf.charAt(10))) return false;
        return true;
      }),
    phone: Yup.string().required("*Obrigatório"),
    city: Yup.string().required("*Obrigatório"),
    state: Yup.string().required("*Obrigatório"),
    whatsApp: Yup.string().required("*Obrigatório"),
    instagram: Yup.string().required("*Obrigatório"),
    description: Yup.string().required("*Obrigatório"),
    avatar_url: Yup.string().required("*Obrigatório"),
    video_url: Yup.string().required("*Obrigatório"),
    why: Yup.string().required("*Obrigatório"),
    resilience: Yup.string().required("*Obrigatório"),
    repertoire: Yup.string().required("*Obrigatório"),
    music_fav: Yup.string().required("*Obrigatório"),
    study_unicesumar: Yup.boolean()
      .required("*Obrigatório")
      .oneOf([true, false], "*Obrigatório"),
    accept: Yup.boolean()
      .required("Os termos e condições devem ser aceitos.")
      .oneOf([true], "Os termos e condições devem ser aceitos."),
  });

  const SignupSchemaSingle = Yup.object().shape({
    name: Yup.string().required("*Obrigatório"),
    email: Yup.string().email("E-mail inválido").required("*Obrigatório"),
    phone: Yup.string().required("*Obrigatório"),
    city: Yup.string().required("*Obrigatório"),
    state: Yup.string().required("*Obrigatório"),
  });

  const candidateFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      document: "",
      phone: "",
      city: "",
      state: "",
      whatsApp: "",
      instagram: "",
      description: "",
      why: "",
      resilience: "",
      avatar_url: "",
      video_url: "",
      repertoire: "",
      music_fav: "",
      study_unicesumar: undefined,
      accept: false,
    },
    validationSchema: SignupSchema,
    onSubmit: async (values: ICandidateRegister) => {
      try {
        setLoading(true);
        let ret: ICandidate = await createCandidate(values);
        setCandidate(ret);
        setLoading(false);
        let crmInfo: ICandidateCRM = {
          nome: values.name,
          cpf: values.document,
          email: values.email,
          celular: values.whatsApp,
          telefone: values.phone,
          polo: "304",
          cp_campanha: "DOM Reality 2023",
          cp_origem: "CANDIDATO",
          como_conheceu: "DOM Reality 2023",
          fl_aceite_info: "1",
          tipo: "L",
          modalidade: "GRAD",
        };

        // axios.get("https://api.unicesumar.edu.br/v1/ead/lead/contato/salvar", {
        //   params: crmInfo,
        // });

        toast.success("Candidatura realizada!");
        setThanks(true);
        candidateFormik.resetForm();
      } catch (e: any) {
        toast.error(e.message);
        setLoading(false);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      city: "",
      state: "",
    },
    validationSchema: SignupSchemaSingle,
    onSubmit: async (values: IVoterRegister) => {
      try {
        setLoading(true);
        await createVoter(values);
        setLoading(false);
        toast.success("Agora você você pode votar");
        formik.resetForm();
      } catch (e: any) {
        toast.error(e.message);
        setLoading(false);
      }
    },
  });

  return (
    <div className="form-container">
      <Box maxWidth="650px" m="auto">
        {thanks && candidate ? (
          <ThanksParticipate {...candidate} />
        ) : (
          <Fragment>
            {/* <Box display="flex" justifyContent="center" mb="40px">
            <Box sx={{
              cursor: "pointer",
              padding: "20px 30px",
              backgroundColor: !isCandidate ? "#009db8" : "#003872",
              color: "#ffffff",
              borderTopLeftRadius: "30px",
              borderBottomLeftRadius: "30px"
            }} onClick={() => { setIsCandidate(true) }}>quero me inscrever</Box>
            <Box sx={{
              cursor: "pointer",
              padding: "20px 30px",
              backgroundColor: isCandidate ? "#009db8" : "#003872",
              color: "#ffffff",
              borderTopRightRadius: "30px",
              borderBottomRightRadius: "30px"
            }} onClick={() => document.getElementById('section-robo-desclassifica')?.scrollIntoView({ behavior: 'smooth' })}>quero votar</Box>
          </Box> */}

            <Box mb="40px" color="#ffffff">
              <h1
                className="nebulica-regular"
                style={{ fontSize: matches ? "48px" : "72px" }}
              >
                3ª TEMPORADA //
              </h1>
              <h2
                className="source-code-pro-elight"
                style={{ fontSize: "23px" }}
              >
                Se inscreva agora mesmo e participe do primeiro reality show de
                música gospel do Brasil
              </h2>
            </Box>
            <Box>
              <form onSubmit={candidateFormik.handleSubmit}>
                <Box display="flex" gap="15px" flexDirection="column">
                  <CssTextField
                    fullWidth
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Nome completo:"
                    onChange={candidateFormik.handleChange}
                    value={candidateFormik.values.name}
                  />
                  {candidateFormik.touched.name &&
                  candidateFormik.errors.name ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.name}
                    </Box>
                  ) : null}
                  <CssTextField
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="E-mail:"
                    type="text"
                    onChange={candidateFormik.handleChange}
                    value={candidateFormik.values.email}
                  />
                  {candidateFormik.touched.email &&
                  candidateFormik.errors.email ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.email}
                    </Box>
                  ) : null}
                  <Box display="flex" gap="15px" width="100%">
                    <Box width={"100%"}>
                      <ReactInputMask
                        mask={cpfMask}
                        value={candidateFormik.values.document}
                        onChange={candidateFormik.handleChange}
                        render={(ref, props) => (
                          <CssTextField
                            {...props}
                            fullWidth
                            id="document"
                            name="document"
                            type="text"
                            placeholder="CPF:"
                            inputRef={ref}
                            variant="outlined"
                          />
                        )}
                      ></ReactInputMask>

                      {candidateFormik.touched.document &&
                      candidateFormik.errors.document ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.document}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Box display="flex" gap="15px" width="100%" flexWrap={"wrap"}>
                    <Box flex="1">
                      <CssSelect
                        fullWidth
                        id="state"
                        name="state"
                        type="text"
                        displayEmpty
                        onChange={candidateFormik.handleChange}
                        value={candidateFormik.values.state}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione o estado</em>
                        </MenuItem>
                        {estadosData.estados.map((state) => (
                          <MenuItem key={state.sigla} value={state.sigla}>
                            {state.nome}
                          </MenuItem>
                        ))}
                      </CssSelect>
                      {candidateFormik.touched.state &&
                      candidateFormik.errors.state ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.state}
                        </Box>
                      ) : null}
                    </Box>
                    <Box flex="1">
                      <CssSelect
                        fullWidth
                        id="city"
                        name="city"
                        type="text"
                        displayEmpty
                        onChange={candidateFormik.handleChange}
                        value={candidateFormik.values.city}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione a cidade</em>
                        </MenuItem>
                        {estadosData.estados
                          .filter(
                            (state) =>
                              state.sigla === candidateFormik.values.state
                          )[0]
                          ?.cidades?.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                      </CssSelect>
                      {candidateFormik.touched.city &&
                      candidateFormik.errors.city ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.city}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Box display="flex" gap="15px" width="100%">
                    <Box flex="1">
                      <ReactInputMask
                        mask={phoneMask}
                        value={candidateFormik.values.whatsApp}
                        onChange={(e) => {
                          candidateFormik.handleChange(e);
                          candidateFormik.setFieldValue(
                            "phone",
                            e.target.value
                          );
                        }}
                        render={(ref, props) => (
                          <CssTextField
                            {...props}
                            fullWidth
                            id="whatsApp"
                            name="whatsApp"
                            type="text"
                            placeholder="WhatsApp:"
                            inputRef={ref}
                            variant="outlined"
                          />
                        )}
                      ></ReactInputMask>

                      {candidateFormik.touched.whatsApp &&
                      candidateFormik.errors.whatsApp ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.whatsApp}
                        </Box>
                      ) : null}
                    </Box>
                    <Box flex="1">
                      <CssTextField
                        fullWidth
                        id="instagram"
                        name="instagram"
                        type="text"
                        placeholder="Instagram:"
                        onChange={candidateFormik.handleChange}
                        value={candidateFormik.values.instagram}
                      />
                      {candidateFormik.touched.instagram &&
                      candidateFormik.errors.instagram ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.instagram}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <CssTextField
                    fullWidth
                    id="why"
                    name="why"
                    type="text"
                    multiline
                    minRows={3}
                    maxRows={8}
                    placeholder="Porque se inscreveu no Dom Reality?"
                    onChange={candidateFormik.handleChange}
                    value={candidateFormik.values.why}
                  />
                  {candidateFormik.touched.why && candidateFormik.errors.why ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.why}
                    </Box>
                  ) : null}
                  <CssTextField
                    fullWidth
                    id="resilience"
                    name="resilience"
                    type="text"
                    multiline
                    minRows={3}
                    maxRows={8}
                    placeholder="Qual seu maior momento de superação?"
                    onChange={candidateFormik.handleChange}
                    value={candidateFormik.values.resilience}
                  />
                  {candidateFormik.touched.resilience &&
                  candidateFormik.errors.resilience ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.resilience}
                    </Box>
                  ) : null}
                  <CssTextField
                    fullWidth
                    id="description"
                    name="description"
                    type="text"
                    multiline
                    minRows={3}
                    maxRows={8}
                    placeholder="Sobre você:"
                    onChange={candidateFormik.handleChange}
                    value={candidateFormik.values.description}
                  />
                  {candidateFormik.touched.description &&
                  candidateFormik.errors.description ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.description}
                    </Box>
                  ) : null}
                  <Box flex="1">
                    <CssSelect
                      fullWidth
                      id="study_unicesumar"
                      name="study_unicesumar"
                      displayEmpty
                      onChange={candidateFormik.handleChange}
                      value={candidateFormik.values.study_unicesumar}
                    >
                      <MenuItem disabled>
                        <em>Estuda ou já estudou na Unicesumar?</em>
                      </MenuItem>
                      {[
                        { label: "Sim", value: "true" },
                        { label: "Não", value: "false" },
                      ].map((studyUnisesumar) => (
                        <MenuItem
                          key={studyUnisesumar.label}
                          value={studyUnisesumar.value}
                        >
                          {studyUnisesumar.label}
                        </MenuItem>
                      ))}
                    </CssSelect>
                    {candidateFormik.touched.study_unicesumar &&
                    candidateFormik.errors.study_unicesumar ? (
                      <Box textAlign="left" color="red">
                        {candidateFormik.errors.study_unicesumar}
                      </Box>
                    ) : null}
                  </Box>
                  <CssTextField
                    fullWidth
                    id="music_fav"
                    name="music_fav"
                    type="text"
                    placeholder="Qual música você cantaria no Dom T3?"
                    onChange={candidateFormik.handleChange}
                    value={candidateFormik.values.music_fav}
                  />
                  {candidateFormik.touched.music_fav &&
                  candidateFormik.errors.music_fav ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.music_fav}
                    </Box>
                  ) : null}
                  <Box display="flex" gap="15px" width="100%" flexWrap={"wrap"}>
                    {/* <Box flex="1">
                      <CssSelect
                        fullWidth
                        id="music_fav"
                        name="music_fav"
                        type="text"
                        displayEmpty
                        onChange={candidateFormik.handleChange}
                        value={candidateFormik.values.music_fav}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione uma música favorita</em>
                        </MenuItem>
                        {musicsData.musics.map((music) => (
                          <MenuItem key={music} value={music}>
                            {music}
                          </MenuItem>
                        ))}
                      </CssSelect>
                      {candidateFormik.touched.music_fav &&
                      candidateFormik.errors.music_fav ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.music_fav}
                        </Box>
                      ) : null}
                    </Box> */}
                    <Box flex="1">
                      <CssSelect
                        fullWidth
                        id="repertoire"
                        name="repertoire"
                        type="text"
                        displayEmpty
                        onChange={candidateFormik.handleChange}
                        value={candidateFormik.values.repertoire}
                      >
                        <MenuItem value="" disabled>
                          <em>Selecione o tom</em>
                        </MenuItem>
                        {repertoiresData.repertoires.map((repertoire) => (
                          <MenuItem key={repertoire} value={repertoire}>
                            {repertoire}
                          </MenuItem>
                        ))}
                      </CssSelect>
                      {candidateFormik.touched.repertoire &&
                      candidateFormik.errors.repertoire ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.repertoire}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    gap={"15px"}
                    flexWrap={"wrap"}
                  >
                    <Box flex="1">
                      <UploadPhoto {...candidateFormik} />
                      {candidateFormik.touched.avatar_url &&
                      candidateFormik.errors.avatar_url ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.avatar_url}
                        </Box>
                      ) : null}
                    </Box>
                    <Box flex="1">
                      <UploadVideo {...candidateFormik} />
                      {candidateFormik.touched.video_url &&
                      candidateFormik.errors.video_url ? (
                        <Box textAlign="left" color="red">
                          {candidateFormik.errors.video_url}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  {/* <FormControlLabel
                    className="source"
                    control={
                      <Checkbox
                        checkedIcon={<CircleChecked />}
                        icon={<RadioButtonUncheckedIcon />}
                        checked={candidateFormik.values.study_unicesumar}
                        onChange={(e) =>
                          candidateFormik.setFieldValue(
                            "study_unicesumar",
                            e.target.checked
                          )
                        }
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                        }}
                      />
                    }
                    label={
                      <Box color="#ffffff" textAlign={"left"} fontSize={"12px"}>
                        ESTUDA OU JÁ ESTUDOU NA UNICESUMAR?
                      </Box>
                    }
                  /> */}
                  <FormControlLabel
                    className="source"
                    control={
                      <Checkbox
                        checkedIcon={<CircleChecked />}
                        icon={<RadioButtonUncheckedIcon />}
                        value={candidateFormik.values.accept}
                        onChange={(e) =>
                          candidateFormik.setFieldValue(
                            "accept",
                            e.target.checked
                          )
                        }
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                          },
                        }}
                      />
                    }
                    label={
                      <Box color="#ffffff" textAlign={"left"} fontSize={"12px"}>
                        AO CLICAR AQUI, EU CONCORDO COM O{" "}
                        <a href="/regulamento.pdf" target={"_blank noopener"}>
                          <u>REGULAMENTO DO REALITY GOSPEL - DOM</u>{" "}
                        </a>{" "}
                        E AUTORIZO O USO DE MINHA IMAGEM E VOZ.
                      </Box>
                    }
                  />
                  {candidateFormik.touched.accept &&
                  candidateFormik.errors.accept ? (
                    <Box textAlign="left" color="red">
                      {candidateFormik.errors.accept}
                    </Box>
                  ) : null}
                  <Box textAlign="center" marginTop={5}>
                    <ButtonReality
                      id="btn-inscription"
                      disabled={loading}
                      type="submit"
                    >
                      {loading && <CircularProgress />}
                      Enviar
                    </ButtonReality>
                  </Box>
                </Box>
              </form>
            </Box>
          </Fragment>
        )}
      </Box>
    </div>
  );
};

export default Participation;
