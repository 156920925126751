import { Box, Container } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import Footer from "./components/Footer";
// import Newsletter from "./components/Newsletter";
import Participation from "./components/Participation";
import { OutlinedButtonReality } from "./components/button";
import { WhatsApp } from "@mui/icons-material";
import { isBefore, parseISO } from "date-fns";
import { useState } from "react";
import { useEffect } from "react";

function App() {
  const matches = useMediaQuery("(max-width:600px)");
  const [isBeforeDeadline, setIsBeforeDeadline] = useState(true);

  useEffect(() => {
    const checkDeadline = () => {
      const currentDateTime = new Date();
      const deadline = parseISO("2024-11-02T23:59:59");

      setIsBeforeDeadline(isBefore(currentDateTime, deadline));
    };

    // Executa a checagem a cada minuto
    checkDeadline(); // Checagem inicial
    const intervalId = setInterval(checkDeadline, 60000); // Atualiza a cada 60 segundos (1 minuto)

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente desmonta
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dom Reality - T3</title>
        <link rel="canonical" href="https://musicaldom.com.br" />
        <meta
          name="description"
          content="O Reality Show de música gospel que vai apresentar a grande voz que o Brasil precisa conhecer!
É a sua chance. Se inscreva ou compartilhe com alguém que precisa saber disso."
        />
      </Helmet>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section
        style={{
          padding: 0,
          display: "flex",
          flexDirection: matches ? "column" : "row",
        }}
      >
        <Box flex={1} className="hero-left" padding={"40px"}>
          <Box maxWidth={"550px"} ml={"auto"} pb={"200px"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={matches ? "column" : "row"}
            >
              <img
                style={{ width: "100%", maxWidth: "300px" }}
                src="/images/logo_header.png"
                alt="Logo Dom"
              />
              <span
                className="nebulica-regular"
                style={{
                  color: "white",
                  fontSize: "24px",
                  marginTop: matches ? "25px" : "0",
                }}
              >
                3ª TEMPORADA
              </span>
            </Box>
            <Box textAlign={"left"} mt={"36px"}>
              <img src="/images/star.png" alt="Estrela" />
            </Box>
            <h1
              style={{
                fontSize: "59px",
                textAlign: "left",
                marginBottom: "48px",
              }}
              className="nebulica-medium"
            >
              O REALITY SHOW QUE REVELA NOVOS DONS!
            </h1>
            <p className="suse" style={{ fontSize: "26px", textAlign: "left" }}>
              O melhor cantor da temporada ganha um contrato com uma grande
              gravadora e muito mais!
            </p>
            <Box
              display={"flex"}
              gap={2}
              mt={"52px"}
              flexDirection={matches ? "column" : "row"}
            >
              <OutlinedButtonReality
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send?text=Você conhece o Dom Reality?%0AÉ a chance do Brasil conhecer o seu dom!%0ASe inscreva agora mesmo!"
                  )
                }
                variant="outlined"
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <WhatsApp />
                  <span
                    className="source-code-pro-elight"
                    style={{ marginLeft: "15px", fontSize: "21px" }}
                  >
                    Compartilhe
                  </span>
                </Box>
              </OutlinedButtonReality>
            </Box>
          </Box>
        </Box>
        <Box flex={1} className="hero-right"></Box>
      </section>
      <main id="main">
        <section id="juradosReality">
          <h2
            className="nebulica-regular"
            style={{
              fontSize: matches ? "48px" : "78px",
              marginBottom: "41px",
            }}
          >
            CONHEÇA OS JURADOS DA T3
          </h2>
          <p
            className="source-code-pro-elight"
            style={{
              fontSize: "35px",
              fontWeight: "100",
              color: "#0b0b0b",
              marginBottom: "72px",
            }}
          >
            A cada semana um novo nome será divulgado
          </p>
          <Container maxWidth={"xl"} className="marketing">
            <div>
              <img
                src="/images/Jurado_PA.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_AEF.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_AV.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_GS.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_JS.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_JV.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_LC.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
            <div>
              <img
                src="/images/Jurado_TR.png"
                width="255"
                height="auto"
                alt="Jurado"
              />
            </div>
          </Container>
        </section>
        {isBeforeDeadline && <Participation />}
        {/* <Newsletter /> */}
        <section className="about">
          <Container>
            <Box
              className="section-title"
              width={"100%"}
              display="flex"
              gap={"50px"}
              flexWrap={"wrap"}
              flexDirection={matches ? "column" : "row"}
            >
              <Box flex="1">
                <Box textAlign={"left"}>
                  <h2>O QUE É O DOM REALITY?</h2>
                  <p>
                    É um reality show musical gospel que revela novos talentos e
                    se conecta com todo brasileiro amante de uma boa música. Ele
                    tem o objetivo de descobrir novos cantores, proporcionando
                    uma plataforma para que eles mostrem suas habilidades
                    musicais e cresçam em suas carreiras.
                  </p>
                </Box>
              </Box>
              <Box flex="1">
                <Box textAlign={"left"}>
                  <h2>QUEM ESTÁ ORGANIZANDO?</h2>
                  <p>
                    O Dom Reality é organizado pela Criativum, e conta com
                    grandes parceiros, como a Unicesumar, nossa patrocinadora
                    desde a primeira edição.
                  </p>
                </Box>
              </Box>
            </Box>
            <Box
              className="section-title"
              width={"100%"}
              display="flex"
              gap={"50px"}
              flexWrap={"wrap"}
              flexDirection={matches ? "column" : "row"}
            >
              <Box flex="1">
                <Box textAlign={"left"}>
                  <h2>QUEM PODE PARTICIPAR</h2>
                  <p>
                    Todo aquele que entende seu talento na música e quer mostra
                    para o mundo. Necessário ter acima de 16 anos e seguir as
                    regras do regulamento.
                  </p>
                </Box>
              </Box>
              <Box flex="1">
                <Box textAlign={"left"}>
                  <h2>CONHEÇA A PREMIAÇÃO //</h2>
                  <p>
                    O grande vencedor da terceira edição terá um contrato de
                    distribuição no selo Dom em uma grande gravadora, além da
                    produção musical de 3 músicas, um contrato de gestão de
                    carreira, uma produção audiovisual de uma das músicas, ação
                    exclusiva com a Multitracks, uma bolsa integral de estudos
                    em um curso EAD na UniCesumar, além de outros prêmios que
                    serão anunciados no decorrer do programa.
                  </p>
                </Box>
              </Box>
            </Box>
          </Container>
        </section>
      </main>
      <Footer />
      <div className="box-cookies hide">
        <div className="row">
          <div className="col">
            <p className="msg-cookies">
              Usamos cookies para permitir que o nosso website funcione
              corretamente, para personalizar conteúdo e anúncios, para
              proporcionar funcionalidades das redes sociais e para analisar o
              nosso tráfego. Também partilhamos informação com os nossos
              parceiros das redes sociais, de publicidade e de dados sobre o seu
              uso do nosso website.
            </p>
          </div>
          <div className="col-auto">
            <button className="btn-cookies button-primary">Eu aceito</button>
            <button
              type="button"
              className="btn btn-primary btn-cookies"
              onClick={() => window.location.replace("/lgpd")}
            >
              Preferências
            </button>
            <button className="btn-cookies button-primary btn-recusar">
              Recusar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
