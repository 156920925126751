import { Box, Container, useMediaQuery } from "@mui/material";

export default function Footer() {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <footer id="footer">
      <Container style={{ marginBottom: "68px", marginTop: "68px" }}>
        <Box
          display={"flex"}
          gap={"20px"}
          flexWrap={"wrap"}
          className="patrocinadores"
        >
          <Box flex={1}>
            <span className="nebulica-regular">APRESENTA</span>
            <Box textAlign={"center"} mt={"20px"}>
              <img src="/images/logo-unicesumar.png" alt="Unicesumar" />
            </Box>
          </Box>
          <Box flex={1}>
            <span className="nebulica-regular">
              PATROCINADOR
              <br />
              MASTER
            </span>
          </Box>
          <Box flex={1}>
            <span className="nebulica-regular">PATROCINADORES</span>
          </Box>
          <Box flex={1}>
            <span className="nebulica-regular">APOIO</span>
            <Box textAlign={"center"} mt={"10px"}>
              <img
                style={{ maxWidth: "150px" }}
                src="/images/LogoSoMultiforme.png"
                alt="Multiform"
              />
            </Box>
          </Box>
        </Box>
        <img
          style={{ maxWidth: "100%", marginTop: "30px" }}
          src="/images/line-sponsors.png"
          alt="line"
        />
        {/* <Box textAlign={"left"} mt={"70px"}>
          <img src="/images/logo-unicesumar.png" alt="Unicesumar" />
        </Box> */}
      </Container>
      <div className="rodape-unicesumar">
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: matches ? "column" : "row",
          }}
        >
          <Box mr={matches ? "auto" : "95px"}>
            <h3
              className="nebulica-regular"
              style={{ fontSize: matches ? "60px" : "97px" }}
            >
              ESTAMOS ON_
            </h3>
          </Box>
          <div className="social-links">
            <div>
              <a
                href="https://www.instagram.com/dom.reality/"
                target="_blank noopener"
                className="instagram btn-social"
              >
                <Box m="5px" width="48px" height="48px">
                  <svg
                    version="1.1"
                    fill="#f53400"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.7 56.7"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        d="M28.2,16.7c-7,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8S41,36.5,41,29.5S35.2,16.7,28.2,16.7z M28.2,37.7
        c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2S32.7,37.7,28.2,37.7z"
                      />
                      <circle cx="41.5" cy="16.4" r="2.9" />
                      <path
                        d="M49,8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7,0-14.5,5.8-14.5,14.5v20.5c0,4.3,1.4,8,4.2,10.7c2.7,2.6,6.3,3.9,10.4,3.9
        h20.4c4.3,0,7.9-1.4,10.5-3.9c2.7-2.6,4.1-6.3,4.1-10.6V19.3C53,15.1,51.6,11.5,49,8.9z M48.6,39.9c0,3.1-1.1,5.6-2.9,7.3
        s-4.3,2.6-7.3,2.6H18c-3,0-5.5-0.9-7.3-2.6C8.9,45.4,8,42.9,8,39.8V19.3c0-3,0.9-5.5,2.7-7.3c1.7-1.7,4.3-2.6,7.3-2.6h20.6
        c3,0,5.5,0.9,7.3,2.7c1.7,1.8,2.7,4.3,2.7,7.2V39.9L48.6,39.9z"
                      />
                    </g>
                  </svg>
                </Box>
              </a>
            </div>
            <div>
              <a
                href="https://www.youtube.com/@DomRealityOficial/"
                target="_blank noopener"
                className="youtube btn-social"
              >
                <Box m="5px" width="48px" height="48px">
                  <svg
                    height="100%"
                    style={{
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                    }}
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="100%"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <path
                        d="M501.299,132.766c-5.888,-22.03 -23.234,-39.377 -45.264,-45.264c-39.932,-10.701 -200.037,-10.701 -200.037,-10.701c0,0 -160.105,0 -200.038,10.701c-22.025,5.887 -39.376,23.234 -45.264,45.264c-10.696,39.928 -10.696,123.236 -10.696,123.236c0,0 0,83.308 10.696,123.232c5.888,22.03 23.239,39.381 45.264,45.268c39.933,10.697 200.038,10.697 200.038,10.697c0,0 160.105,0 200.037,-10.697c22.03,-5.887 39.376,-23.238 45.264,-45.268c10.701,-39.924 10.701,-123.232 10.701,-123.232c0,0 0,-83.308 -10.701,-123.236Z"
                        style={{ fill: "#f53400", fillRule: "nonzero" }}
                      />
                      <path
                        d="M204.796,332.803l133.018,-76.801l-133.018,-76.801l0,153.602Z"
                        style={{ fill: "#291e78", fillRule: "nonzero" }}
                      />
                    </g>
                  </svg>
                </Box>
              </a>
            </div>
            <div>
              <a
                href="https://www.tiktok.com/@dom.reality"
                target="_blank noopener"
                className="tiktok btn-social"
              >
                <Box m="5px" width="40px" height="40px">
                  <svg
                    viewBox="0 0 448 512"
                    fill="#f53400"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
                  </svg>
                </Box>
              </a>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="rodape-unicesumar-3"
        style={{
          backgroundImage: "url(/images/elipse_rodape.png)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "50px 0",
          borderTop: "none",
        }}
      >
        <img width={"300px"} src="/images/logo_dom_rodape.png" alt="Logo Dom" />
      </div>
    </footer>
  );
}
