import { Facebook, Telegram, WhatsApp } from "@mui/icons-material";
import { Avatar, Box } from "@mui/material";
import { ICandidate } from "../interfaces/inscription";
import ButtonReality from "./button";
import { ButtonReality2 } from './button'

export const ThanksParticipate = (candidate: ICandidate) => {
  return (
    <Box
      p="20px"
      textAlign={"center"}
      justifyContent={"center"}
      display="flex"
      gap="15px"
      flexDirection={"column"}
    >
      <Box display="flex" justifyContent={"center"} gap={"15px"}>
        <Box className="suse" mb={"15px"} color={"white"}>
          Sua inscrição está 90% concluída e só falta entrar no grupo para
          finalizar!
        </Box>
        <ButtonReality2 className="btn-share" type="submit">
          <a
            href={`https://t.me/+pTKjKmtw6-Q4YmFh`}
            target={"_blank  noopener"}
          >
            <Box color="#ffffff" display="flex" gap="5px" alignItems={"center"}>
              <Telegram />
              Entrar no grupo
            </Box>
          </a>
        </ButtonReality2>
      </Box>
      <Box color="#ffffff" fontSize={"18px"}>
        {candidate.person.name}
      </Box>
      <Box display={"flex"} justifyContent={"center"}>
        <Avatar
          sx={{ width: "100px", height: "100px" }}
          src={candidate.person.avatar_url}
        />
      </Box>
      <Box color="#ffffff" sx={{ wordBreak: "break-word" }}>
        {candidate.person.description}
      </Box>
      <Box display="flex" justifyContent={"center"} gap={"15px"}>
        <ButtonReality className="btn-share" type="submit">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.domreality.com.br&t=Hey%21+Estou+participando+do+Dom+Reality+T3%21+Vem+tamb%C3%A9m%21`}
            target={"_blank  noopener"}
          >
            <Box color="#ffffff" display="flex" gap="5px" alignItems={"center"}>
              <Facebook />
              Compartilhar
            </Box>
          </a>
        </ButtonReality>
        <ButtonReality className="btn-share" type="submit">
          <a
            href={`https://api.whatsapp.com/send?text=Hey! Estou participando do Dom Reality T3!%0AVem também!!%20www.domreality.com.br%20`}
            target={"_blank  noopener"}
          >
            <Box color="#ffffff" display="flex" gap="5px" alignItems={"center"}>
              <WhatsApp />
              WhatsApp
            </Box>
          </a>
        </ButtonReality>
      </Box>
    </Box>
  );
};
